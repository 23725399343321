var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dn-loop-download-modal",
      attrs: {
        value: _vm.show,
        overlay: true,
        "max-width": "700px",
        transition: "dialog-transition",
      },
      on: {
        input: function ($event) {
          return _vm.$emit("toggle")
        },
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dn-loop-download-modal__card" },
        [
          _c("v-card-title", [
            _c("h2", { staticClass: "mb-3 mt-3" }, [
              _vm._v("Download " + _vm._s(_vm.loopName)),
            ]),
          ]),
          _c("v-card-text", [
            _c("h3", [_vm._v("Available credits: " + _vm._s(_vm.credits))]),
          ]),
          _c("v-card-text", [
            _c("h3", [_vm._v(" " + _vm._s(_vm.tempoName) + " ")]),
            _c("h3", [_vm._v(" " + _vm._s(_vm.styleName) + " ")]),
          ]),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: { click: _vm.downloadLoop },
                },
                [_vm._v("MP3")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: { click: _vm.downloadMidi },
                },
                [_vm._v("MIDI")]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", outlined: "" },
                  on: { click: _vm.downloadMidiMap },
                },
                [_vm._v("MIDI Map")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }