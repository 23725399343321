var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dn-checkout-modal",
      attrs: {
        overlay: true,
        "max-width": "500px",
        transition: "dialog-transition",
      },
      on: {
        input: function ($event) {
          return _vm.$emit("toggle")
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "dn-checkout-modal__card" },
        [
          _c("v-card-title", [
            _c(
              "h2",
              { staticClass: "mb-3 mt-3" },
              [
                !_vm.checkCart()
                  ? [_vm._v(" Add to Cart ")]
                  : [_vm._v(" Pack is in your Cart! ")],
              ],
              2
            ),
          ]),
          !_vm.loading
            ? _c(
                "v-card-text",
                [
                  _c("h3", { staticClass: "mt-10" }, [
                    _vm._v(" " + _vm._s(_vm.pack.name) + " "),
                  ]),
                  _c("p", [_vm._v(_vm._s("$" + _vm.pack.price))]),
                  _vm.checkCart()
                    ? _c(
                        "router-link",
                        {
                          staticClass: "dn-checkout-modal__cart-btn",
                          attrs: { to: "/cart", color: "primary" },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "dn-checkout-modal__cart-btn-text" },
                            [_vm._v(" Go to Cart ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.checkCart()
                    ? _c(
                        "button",
                        {
                          staticClass: "dn-checkout-modal__cart-btn",
                          attrs: { outlined: "" },
                          on: { click: _vm.addToCart },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "dn-checkout-modal__cart-btn-text" },
                            [_vm._v(" Add to Cart ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "v-card-text",
                [
                  _c("v-progress-circular", {
                    staticClass: "ma-auto",
                    attrs: { indeterminate: "" },
                  }),
                ],
                1
              ),
          _c(
            "v-card-actions",
            { staticClass: "pa-6" },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    outlined: "",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.checkCart()
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", outlined: "" },
                      on: { click: _vm.removeFromCart },
                    },
                    [_vm._v("Remove From Cart")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }