var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-pack-header" },
    [
      _c("back-button", {
        staticClass: "dn-pack-header__back",
        on: { back: _vm.handleBack },
      }),
      _c("div", { staticClass: "dn-pack-header__content dn-container" }, [
        _vm.$vuetify.breakpoint.mdAndUp
          ? _c("img", {
              staticClass: "dn-pack-header__thumbnail",
              attrs: { src: _vm.pack.thumbnailUrl },
            })
          : _vm._e(),
        _c(
          "div",
          { staticClass: "dn-pack-header__meta" },
          [
            _c("p", { staticClass: "dn-pack-header__meta__genre" }, [
              _vm._v(_vm._s(_vm.pack.genre.name)),
            ]),
            _c("h1", { staticClass: "dn-pack-header__meta__title" }, [
              _vm._v(_vm._s(_vm.pack.name)),
            ]),
            _vm.$vuetify.breakpoint.mdAndUp || _vm.showDetails
              ? [
                  _c(
                    "p",
                    { staticClass: "dn-pack-header__meta__description" },
                    [_vm._v(" " + _vm._s(_vm.pack.description) + " ")]
                  ),
                ]
              : _vm._e(),
            _vm.loading
              ? _c("v-progress-circular", { attrs: { indeterminate: "" } })
              : [
                  _c(
                    "div",
                    { staticClass: "dn-pack-header__actions" },
                    [
                      _vm.canPurchase
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "mr-3",
                              attrs: { outlined: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("purchase")
                                },
                              },
                            },
                            [
                              _c("div", { staticStyle: { color: "white" } }, [
                                _vm.packInCart
                                  ? _c("span", [_vm._v("Added to Cart")])
                                  : _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.formattedPrice) + " "
                                      ),
                                    ]),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.canDownload
                        ? _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("add-to-library")
                                },
                              },
                            },
                            [_vm._v(" Download ")]
                          )
                        : !_vm.canPurchase
                        ? _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({ path: "/login" })
                                },
                              },
                            },
                            [_vm._v(" Login to Purchase ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
            _c(
              "v-col",
              { staticClass: "mt-3 dn-pack-header__tags" },
              [
                _c(
                  "v-row",
                  [_c("tag-list", { attrs: { list: _vm.pack.tags } })],
                  1
                ),
              ],
              1
            ),
            _vm.$vuetify.breakpoint.smAndDown
              ? _c(
                  "v-btn",
                  {
                    attrs: { text: "", "x-small": "" },
                    on: {
                      click: function ($event) {
                        _vm.showDetails = !_vm.showDetails
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s("Show " + (!_vm.showDetails ? "More" : "Less")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c("div", _vm._b({}, "div", _vm.headerBgAttrs, false)),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }