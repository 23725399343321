var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dn-pack" },
    [
      _vm.packLoaded && _vm.pack
        ? _c("pack-header", {
            attrs: { pack: _vm.pack, loading: _vm.dlLoading },
            on: {
              purchase: _vm.handlePurchase,
              "add-to-library": _vm.handleAddToLibrary,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dn-pack__loops dn-container" },
        [
          _c(
            "transition",
            { attrs: { name: "fade-transition", mode: "out-in" } },
            [
              _vm.packLoaded
                ? _c("loop-table", {
                    key: "table",
                    attrs: {
                      loops: _vm.currentLoopItems,
                      current: _vm.currentPlayerItem,
                      canDownload: _vm.userCanDownloadLoops,
                      playing: _vm.playing,
                      sample: _vm.isSample,
                    },
                    on: {
                      "loop:click": _vm.handleClickRow,
                      "sample:click": _vm.handlePlaySample,
                      "loop-add": _vm.handleAddLoop,
                    },
                  })
                : _c("v-progress-circular", {
                    key: "progress",
                    attrs: { indeterminate: "" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("checkout-modal", {
        attrs: { pack: _vm.pack, show: _vm.checkout },
        on: {
          toggle: function ($event) {
            _vm.checkout = !_vm.checkout
          },
        },
      }),
      _vm.userCanDownloadLoops
        ? _c("loop-download-modal", {
            attrs: {
              show: _vm.downloadLoop,
              variant: _vm.currentDlVariant,
              loop: _vm.currentDlLoop,
              pack: _vm.pack,
            },
            on: {
              toggle: function ($event) {
                _vm.downloadLoop = !_vm.downloadLoop
              },
              refresh: _vm.refreshLibrary,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }