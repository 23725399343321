var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pack-table fill-height justify-center align-start" },
    [
      _c(
        "v-row",
        {
          staticClass: "d-flex flex-column pb-6",
          attrs: { align: "center", justify: "center" },
        },
        [
          _c(
            "v-col",
            { staticClass: "mb-12", attrs: { cols: "13", md: "10" } },
            [
              _c("v-simple-table", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function () {
                      return [
                        _c(
                          "tbody",
                          _vm._l(_vm.loops, function (loop, i) {
                            return _c(
                              "tr",
                              {
                                key: loop.id,
                                staticClass: "pack-table__row",
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("click:row", loop)
                                  },
                                },
                              },
                              [
                                _c("td", [
                                  _c(
                                    "span",
                                    { staticClass: "pack-table__row__index" },
                                    [_vm._v(_vm._s(i + 1))]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "pack-table__row__action" },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            position: "absolute",
                                            top: "-10px",
                                            left: "-5px",
                                          },
                                        },
                                        [_vm._v("play_arrow")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("td", [_vm._v(_vm._s(loop.name))]),
                                _c("td", [_vm._v(_vm._s(loop.category.name))]),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }